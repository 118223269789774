import moment from "moment/moment";

/**
 * Create thumbnail url - resolution
 *
 * @param {string} id - id
 * @param {string} kind - kind
 * @function
 */

export function createThumbnailUrl(id, kind) {

	if (kind) {
		let width = 0;
		let height = 0;

		switch (kind) {
			case "smallest":
				width = 214;
				height = 256;
				break;
			case "small":
				width = 228;
				height = 288;
				break;
			default:
				width = 474;
				height = 600;
				break;
		}

		return `${process.env.REACT_APP_IMAGES}/thumbnails/${id}?width=${width}&height=${height}`;
	}
	
	return `${process.env.REACT_APP_IMAGES}/thumbnails/${id}`;
}

/**
 * Format date by moment
 *
 * @param {string} timestamp - timestamp
 * @param {string} language - language
 * @function
 */

export function formatDate(timestamp, language) {
	// return moment(new Date(timestamp)).format(moment.localeData(language).longDateFormat("L"));
	return moment(new Date(timestamp)).format("DD.MM.YYYY");
}

export function format(formatted, ...params) {
	if (params) {
		for (let arg in params) {
			formatted = formatted.replace("{" + arg + "}", params[arg]);
		}
	}
	return formatted;
}

export const dataScope = ["dctermsCreated", "dctermsIssued", "bsaPrintDate"];
